<template>
  <Wrapper>
    <section class="hero">
      <div class="hero-body">
        <h1 class="title">Seja bem-vindo(a) a Kamino!</h1>
        <p class="mb-3">
          Suas informações já estão conosco e daremos continuidade ao processo
          para criar a estrutura
          <span class="is-italic">offshore</span> de sua empresa!
        </p>
        <p class="mb-3">Abaixo, um detalhamento da sua jornada:</p>
        <b-image
          class="mb-5"
          :src="require('@/assets/image/offshore_process.svg')"
          alt="Etapas do processo offshore"
          ratio="17by5"
        />
        <p class="mb-3">
          Qualquer dúvida, fale conosco através do e-mail
          <a href="mailto:contato@kamino.com.br">contato@kamino.com.br</a> ou
          via
          <a href="https://wa.me/+5511936186584" target="_blank">Whatsapp</a>.
        </p>
      </div>
    </section>
  </Wrapper>
</template>

<script>
import { Wrapper } from '@/components/Wrapper';

export default {
  name: 'CompletedView',
  components: {
    Wrapper,
  },
};
</script>
